var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 12 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-select',{staticStyle:{"width":"100%"},model:{value:(_vm.activeCategoryIndex),callback:function ($$v) {_vm.activeCategoryIndex=$$v},expression:"activeCategoryIndex"}},_vm._l((_vm.categoryList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(_vm._s(item))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"子分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pid',
                            { rules: [{ required: true, message: '请选择！' }] },
                        ]),expression:"[\n                            'pid',\n                            { rules: [{ required: true, message: '请选择！' }] },\n                        ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.subCategory))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            { rules: [{ required: true, message: '请输入！' }] },
                        ]),expression:"[\n                            'name',\n                            { rules: [{ required: true, message: '请输入！' }] },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"说明","label-col":{ span: 3 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),(_vm.form.getFieldValue('pid') !== '5')?_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"附件","label-col":{ span: 3 },"wrapper-col":{ span: 18 }}},[_c('QiniuUpload',{attrs:{"list":_vm.attachmentList,"token":_vm.token},on:{"change":_vm.uploaded}})],1)],1):_vm._e()],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }